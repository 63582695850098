/* eslint-disable no-unused-vars */
import React from 'react'
import Image from 'tcweb-material-components/core/image'
import get from 'tcweb-material-components/core/poly/get'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import intersection from 'lodash/intersection'

const ALLIGNMENT_CLASSES = ['alignwide', 'alignfull']

const useClasses = makeStyles(() => ({
    root: {
        height: 'auto',
        width: ({hasAlignment}) => hasAlignment ? '100%': 'auto',
    }
}))

const ImageSwap = ({ attribs, parent, children, options }) => {
    const { class: className, srcset, height, width, ...rest } = attribs
    const hasAlignment = intersection(ALLIGNMENT_CLASSES, get(parent, 'attribs.class', '').split(' ')).length > 0
    const _classes = useClasses({hasAlignment})
    delete rest.style


    return (
        <Image
            {...rest}
            srcSet={srcset}
            className={classnames(`image-swap ${className}`, _classes.root)}
            backgroundColor='transparent'
            height={!isNaN(height) ? parseInt(height) : null}
            width={!isNaN(width) ? parseInt(width) : null}
        />
    )
}

export default ImageSwap
