import get from 'tcweb-material-components/core/poly/get'
import camelCase from 'tcweb-material-components/core/poly/camelCase'
import reduce from 'tcweb-material-components/core/poly/reduce'

const translateFlex = (data) => {
    const flexAlignments = {
        top: 'flex-start',
        bottom: 'flex-end',
        left: 'flex-start',
        right: 'flex-end'
    }

    return flexAlignments[data] || data
}

const TRANSFORMERS = {
    flex: translateFlex
}

const transformData = (data, category) => {
    if (TRANSFORMERS[category]) return TRANSFORMERS[category](data)
    if (data === null || data === undefined) return data
    if (data === "") return ""
    if (!isNaN(data)) return Number(data)
    if (data === 'false') return false
    if (data === 'true') return true

    return data
}

const wpOptions = (options) =>
    reduce((allData, data, key) => ({
        ...allData,
        [key.split('-')[2]]: {
            ...get(allData, key.split('-')[2]),
            [key.split('-')[1]]: {
                ...get(allData, [key.split('-')[2], key.split('-')[1]], {}),
                [camelCase(key.split('-').slice(3))]: transformData(data, key.split('-')[2])
            }
        }
    }), {}, options)

export const cleanWPValue = value => isNaN(value) ? value : `${value}px`

export default wpOptions
