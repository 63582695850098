/* eslint-disable react/no-children-prop */
import React from 'react'
import curry from 'curry'
import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'
import find from 'tcweb-material-components/core/poly/find'
import get from 'tcweb-material-components/core/poly/get'
import loadable from '@loadable/component'
import WatchIcon from './watchIcon'
import ImageButtonSwap from './imageButtonSwap'
import ImageSwap from './imageSwap'

import wpOptions from '../../utilities/wpOptions'

const HeadingSwap = loadable(() => import('./headingSwap'))
const KadenceTabsSwap = loadable(() => import('./kadenceTabsSwap'))
const NewsFeedSwap = loadable(() => import('./newsSummary'))
const BladeSwap = loadable(() => import('./bladeSwap'))
const DynamicTiles = loadable(() => import('../../components/templates/dynamicTiles'))
const BoxSwap = loadable(() => import('../../components/templates/box'))
const ButtonSwap = loadable(() => import('./buttonSwap'))
const ButtonGroupSwap = loadable(() => import('./buttonGroupSwap'))
const HeroSwap = loadable(() => import('./heroSwap'))
const HeroSimplifiedSwap = loadable(() => import('./heroSimplifiedSwap'))
const VideoControls = loadable(() => import('./videoControls'))
const ColumnsSwap = loadable(() => import('./columnsSwap'))
const MediaTextSwap = loadable(() => import('./mediaTextSwap'))
const ExpansionPanelSwap = loadable(() => import('./expansionPanelSwap'))
const YouTubeEmbedSwap = loadable(() => import('./youtubeEmbedSwap'))
const SeparatorSwap = loadable(() => import('./separatorSwap'))
const BlockQuoteSwap = loadable(() => import('./blockQuoteSwap'))
const PullQuoteSwap = loadable(() => import('./pullQuoteSwap'))
const PreformattedSwap = loadable(() => import('./preformattedSwap'))
const AdvancedGallerySwap = loadable(() => import('./advancedGallerySwap'))
const GallerySwap = loadable(() => import('./gallerySwap'))
const FileSwap = loadable(() => import('./fileSwap'))
const CoverSwap = loadable(() => import('./coverSwap'))

/*
 * Happens within WP Page and Post
 */

const elemeIncludesAttrib = curry((attribName, value, attrib) => get(attrib, attribName, '').includes(value))

const elemIncludesClass = curry((className, elem) => get(elem, 'attribs.class', '').includes(className))

const childrenIncludesElem = curry((name, children) => children.some(child => child.name === name))

const processWpOptions = attribs => wpOptions(pickBy(attribs, (_, key) => startsWith(key, 'data')))

const getHeroBoxProps = children => {
    const box = find(child => get(child, 'attribs.classname') === 'box', children)
    const boxData = processWpOptions(get(box, 'attribs', {}))

    return { box, boxData }
}

const headingsList = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

const options = {
    // attribs = element
    // children = element's children

    replace: ({ attribs, name, children, parent }) => {
        const data = processWpOptions(attribs)


        if (get(attribs, 'class', '').includes('icon-tcs-watch')) {
            const figure = find(child => child.name === 'figure', children)

            if (figure) {
                return <WatchIcon attribs={figure.attribs} children={figure.children} options={options} parent={figure} />
            } else {
                return <WatchIcon attribs={attribs} children={children} options={options} parent={parent} />
            }
        }

        if (name === 'a' && childrenIncludesElem('img', children)) {
            return <ImageButtonSwap attribs={attribs} parent={parent} children={children} options={options} data={data} />
        }

        if (headingsList.includes(name)) {
            return <HeadingSwap attribs={attribs} children={children} options={options} data={data} />
        }

        if (!attribs || !attribs.class) return

        if (attribs.class.includes('dynamic-tiles')) {
            return <DynamicTiles attribs={attribs} children={children} options={options} />
        }

        if (elemeIncludesAttrib('classname', 'uniform-grid', attribs)) {
            return <ColumnsSwap attribs={attribs} children={children} options={options} data={data} />
        }

        // Replace Cover block    -- Match whole class, not 'wp-block-cover__inner-container'
        if (attribs.class.match(/wp-block-cover(\s|$)/g)) {
            return <CoverSwap attribs={attribs} children={children} options={options} data={data} />
        }

        // Replace Tabs with MaterialUI Tabs
        if (attribs.class.includes('wp-block-kadence-tabs') && name === 'div') {
            return <KadenceTabsSwap attribs={attribs} children={children} options={options} />
        }

        // Wrap Hero in <theme provider/>
        if (elemIncludesClass('wp-block-column', parent) && attribs.class.includes('wp-block-custom-hero-block')) {
            return <BladeSwap attribs={attribs} children={children} options={options} data={data} {...getHeroBoxProps(children)} />
        }

        // Regular hero
        if (attribs.class.includes('wp-block-custom-hero-block')) {
            return <HeroSwap attribs={attribs} children={children} options={options} data={data} {...getHeroBoxProps(children)} />
        }

        // Hero simple
        if (attribs.class.includes('wp-block-custom-hero-simplified-block')) {
            return <HeroSimplifiedSwap attribs={attribs} children={children} options={options} data={data} {...getHeroBoxProps(children)} />
        }

        // Add Hero Controls
        if (attribs.class.includes('wp-block-hero__videoContainer')) {
            return <VideoControls attribs={attribs} children={children} />
        }

        // Replace WP Genesis Blocks accordion
        if (attribs.class.includes('wp-block-genesis-blocks-gb-accordion')) {
            return <ExpansionPanelSwap attribs={attribs} children={children} options={options} />
        }

        // Replace News Feed
        if (attribs.class.includes('gears-5-news-feed')) {
            return <NewsFeedSwap attribs={attribs} children={children} options={options} />
        }

        // Replace File block    -- Match whole class, not 'wp-block-file__button'
        if (attribs.class.match(/wp-block-file[\s|$]?/g)) {
            return <FileSwap children={children} options={options} data={data} />
        }

        // Replace all buttons    -- Match whole class, not 'wp-block-button__link'
        if (attribs.class.match(/wp-block-button[^s]/g)) {
            return <ButtonSwap attribs={attribs} children={children} options={options} data={data} />
        }

        // Replace Separator block
        if (attribs.class.includes('wp-block-separator')) {
            return <SeparatorSwap data={data} options={options} attribs={attribs} />
        }

        // Replace BlockQuote block
        if (attribs.class.includes('wp-block-quote')) {
            return <BlockQuoteSwap attribs={attribs} children={children} options={options} />
        }

        // Replace PullQuote block
        if (attribs.class.includes('wp-block-pullquote')) {
            return <PullQuoteSwap attribs={attribs} children={children} options={options}/>
        }

        // Buttons Container Swap
        if (attribs.class.match(/wp-block-buttons/g)) {
            return <ButtonGroupSwap attribs={attribs} children={children} options={options} data={data}/>
        }

        // Replace Media text
        if (attribs.class.match(/wp-block-media-text\b/)) {
            return <MediaTextSwap attribs={attribs} children={children} options={options} data={data} />
        }

        // Replace Box block
        if (elemeIncludesAttrib('classname', 'box', attribs)) {
            return <BoxSwap parent={parent} attribs={attribs} children={children} options={options} data={data} />
        }

        // Replace YouTube embed with a thumbnail and link
        if (attribs.class.match(/wp-block-embed-youtube/g)) {
            return <YouTubeEmbedSwap parent={parent} attribs={attribs} children={children} options={options} data={data} />
        }

        // Replace Verse
        if (attribs.class.includes('wp-block-verse') || attribs.class.includes('wp-block-preformatted')) {
            return <PreformattedSwap attribs={attribs} children={children} options={options} />
        }

        if(attribs.class.includes('wp-block-gallery')){
            return <GallerySwap attribs={attribs} children={children} options={options} />
        }

        if(attribs.class.includes('wp-block-kadence-advancedgallery')){
            return <AdvancedGallerySwap attribs={attribs} children={children} options={options} />
        }

        // Replace all images - webp, srcset
        if (
            // attribs.class.includes('wp-block-hero__image') ||
            // attribs.class.includes('wp-block-hero__videoPoster') ||
            name === 'img'
        ) {
            return <ImageSwap attribs={attribs} parent={parent} children={children} options={options} />
        }
    }
}

export default options
