import React, { useCallback } from 'react'
import curry from 'curry'
import head from 'lodash/head'
import tail from 'lodash/tail'
import { makeStyles } from '@material-ui/styles'
import reactMap from 'tcweb-material-components/core/poly/reactMap'
import reactFlatten from 'tcweb-material-components/core/poly/reactFlatten'
import find from 'tcweb-material-components/core/poly/find'
import get from 'tcweb-material-components/core/poly/get'
import Box from 'tcweb-material-components/core/box'
import classnames from 'classnames'

const backgroundMap = {
    Light: 'bg-default',
    Dark: 'bg-dark'
}

const useClasses = makeStyles(theme => ({
    pageRoot: {
        display: 'flex !important',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        paddingTop: '50px',
        [theme.breakpoints.up('md')]: { paddingTop: '100px' }
    },
    lastSection: {
        paddingBottom: '50px',
        [theme.breakpoints.up('md')]: { paddingBottom: '100px' }
    },
    isNextSpecial: {
        paddingBottom: '50px',
        [theme.breakpoints.up('md')]: { paddingBottom: '100px' }
    },
    isPrevChildSpecial: {
        paddingTop: '50px',
        [theme.breakpoints.up('md')]: { paddingTop: '100px' }
    },
    mediaText: {
        paddingBottom: '50px',
        [theme.breakpoints.down('sm')]: { paddingTop: 0 },
        [theme.breakpoints.up('md')]: { paddingBottom: '100px' }
    },
    mediaTextFirst: {
        paddingTop: '50px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '100px',
            paddingBottom: '100px'
        }
    },
    cover: {
        paddingTop: '20px',
        paddingBottom: '20px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '50px',
            paddingBottom: '50px'
        }
    }
}))

const elemIncludesClass = curry((className, child) =>
    get(child, 'props.attribs.class', get(child, 'props.className', '')).includes(className)
)

const isCover = elemIncludesClass('wp-block-cover')
const isHero = elemIncludesClass('wp-block-custom-hero-block')
const isHeroSimplified = elemIncludesClass('wp-block-custom-hero-simplified-block')
const isBox = elemIncludesClass('box')

const isSrOnly = child => {
    const srOnlyStr = get(child, 'props.attribs.data-sr-only')
    return !!srOnlyStr && JSON.parse(srOnlyStr.toLowerCase()) === true
}

const isHeroOrSimplifiedOrCover = child => isHero(child) || isHeroSimplified(child) || isCover(child)

const isMediaText = child =>
    elemIncludesClass('wp-block-media-text', child)

const isMediaTextWithBg = child =>
    (
        isBox(child) &&
        find(
            child => get(child, 'props.attribs.class', get(child, 'attribs.class', '')).search(/wp-block-media-text/gi) > -1,
            get(child, 'props.children')
        )
    )

const isFullGraphicSection = child =>
    get(child, 'props.attribs.class', get(child, 'attribs.class', '')).search(/alignwide|alignfull/gi) > -1

const isSiblingSpecial = sibling =>
    (isHeroOrSimplifiedOrCover(sibling) || isFullGraphicSection(sibling) || isMediaTextWithBg(sibling)) &&
    !isMediaText(sibling)

const renderContent = _classes => reactMap((child, index, flattenedChildren) => {
    const isLastSection = index + 1 === flattenedChildren.length
    const isFirstSection = index === 0
    const nextChild = isLastSection ? null : flattenedChildren[index + 1]
    const prevChild = isFirstSection ? null : flattenedChildren[index - 1]
    const isPrevChildSpecial = isSiblingSpecial(prevChild)
    const isNextChildSpecial = isSiblingSpecial(nextChild)

    if(!child.type) return null

    if (isMediaText(child)) {
        return React.cloneElement(child, {
            isLastSection,
            className: classnames(
                _classes.mediaText,
                {
                    [_classes.mediaTextFirst]: index === 0,
                    [_classes.lastSection]: isLastSection,
                    [_classes.isPrevChildSpecial]: isPrevChildSpecial,
                    [_classes.isNextSpecial]: isNextChildSpecial,
                },
                get(child, 'props.className', ''),
                get(child, 'props.attribs.class', '')
            )
        })
    }

    return React.cloneElement(child, {
        className: classnames(
            {
                [_classes.section]: !isHeroOrSimplifiedOrCover(child),
                [_classes.lastSection]: isLastSection && !isHeroOrSimplifiedOrCover(child),
                [_classes.isNextSpecial]: isNextChildSpecial && !isHeroOrSimplifiedOrCover(child),
                [_classes.isPrevChildSpecial]: isPrevChildSpecial && !isHeroOrSimplifiedOrCover(child),
                [_classes.cover]: isCover(child)
            },
            get(child, 'props.className', ''),
            get(child, 'props.attribs.class', '')
        )
    })
})

const Page = ({ children, meta, setBackgroundImage = true, gutters = 'page' }) => {
    const _classes = useClasses()
    const _renderContent = useCallback(renderContent(_classes), [_classes])
    const flattenedChildren = reactFlatten(children)

    const isSrOnlyFirst = isSrOnly(head(flattenedChildren))
    const srOnlyElem = isSrOnlyFirst ? flattenedChildren.shift() : <></>

    const firstChild = head(flattenedChildren)
    const backgroundImage = backgroundMap[get(meta, 'background_image')] || 'bg-default'

    if (!firstChild) return null

    if (isHero(firstChild) || isMediaTextWithBg(firstChild)) {
        return (
            <>
                {isSrOnlyFirst && srOnlyElem}
                <Box
                    gutters={gutters}
                    className={classnames(
                        'hero-root',
                        { [_classes.pageRoot]: isMediaTextWithBg(firstChild) }
                    )}
                >
                    {isMediaTextWithBg(firstChild)
                        ? _renderContent([firstChild])
                        : firstChild
                    }
                </Box>
                <Box
                    gutters='page'
                    className={classnames(
                        'page-root',
                        _classes.pageRoot,
                        {
                            [backgroundImage]: setBackgroundImage,
                            'top-overlay': !isMediaTextWithBg(firstChild)
                        }
                    )}
                >
                    {_renderContent(tail(flattenedChildren))}
                </Box>
            </>
        )
    }

    return (
        <>
            {isSrOnlyFirst && srOnlyElem}
            <Box
                gutters='page'
                className={classnames(
                    'page-root',
                    _classes.pageRoot,
                    { [backgroundImage]: setBackgroundImage }
                )}
            >
                {_renderContent(flattenedChildren)}
            </Box>
        </>
    )
}

export default Page
