import React from 'react'
import { makeStyles } from '@material-ui/styles'
import ImageButton from 'tcweb-material-components/core/imageButton'
import find from 'tcweb-material-components/core/poly/find'
import get from 'tcweb-material-components/core/poly/get'

import Link from '../../components/common/link'

const useClasses = makeStyles({
	root: { maxWidth: 'initial' }
})

const ImageButtonSwap = ({ parent, children, attribs, data }) => {
	const image = find(child => child.name === 'img', children)
	const ariaLabel = get(parent, 'attribs.data-aria-label' || '')
	const _classes = useClasses()

	return (
		<ImageButton
			component={Link}
			href={attribs.href}
			target={attribs.target}
			rel={attribs.rel}
			src={image.attribs.src}
			srcSet={image.attribs.srcset}
			alt={image.attribs.alt}
			width={image.attribs.width}
			height={image.attribs.height}
			className={_classes.root}
			aria-label={ariaLabel}
		/>
	)
}

export default ImageButtonSwap
